@import url("https://fonts.googleapis.com/css?family=Roboto:400,400i,500,500i,700&display=swap");
@import url("https://fonts.googleapis.com/css?family=Bree+Serif&display=swap");

:root {
  --mainCherry: #4f0815;
  --mainCherryLight: #cccaca;
  --mainYellow: rgba(249, 228, 148, 9);
  --secYellow: #f7ba15;
  --thirdYellow: rgba(245, 197, 126, 0.2);
  --mainBlue: rgba(14, 168, 245, 0.7);
  --mainWhite: #fff;
  --mainBlack: #000;
  --yellowTrans: rgba(249, 228, 148, 0.5);
  --mainGrey: #eeeeee;
}

html {
  min-height: 100%;
  position: relative;
}

body {
  font-family: "Bree Serif", serif;
  background-color: var(--mainGrey);
  color: var(--mainBlack);
  margin: 0;
  padding: 0;
  overflow-x: hidden !important;
}

a {
  color: var(--mainBlack);
}

a:hover {
  text-decoration: none;
  color: var(--mainBlack);
}

h3 {
  font-size: 1.6rem;
}

h5 {
  font-size: 1.1rem;
}

/* global style */

.btn-blue {
  color: var(--mainWhite);
  background-color: var(--mainBlue);
}

.btn-blue:hover {
  color: var(--mainCherry);
  background-color: var(--mainWhite);
  border-color: var(--mainBlack);
}

.btn-cherry-yellow {
  color: var(--mainWhite);
  background-color: var(--mainCherry);
}

.btn-cherry-yellow:hover {
  color: var(--mainCherry);
  background-color: var(--secYellow);
}

/* nav links */

.navbar {
  background-color: var(--mainCherry);
  font-family: "Bree Serif", serif;
  padding: 0px;
}

.navbar-toggler {
  outline: none !important;
}

.navbar-collapse {
  transition: all 0.5s ease-in-out;
}

.nav-link {
  color: var(--mainGrey);
  transition: all 0.5s ease-in-out;
  font-size: 1.4rem;
}

.logo img {
  height: auto;
  width: 4.5rem;
}

.toggler-icon {
  font-size: 2.5rem;
  color: var(--mainWhite);
}

.phone-icon {
  color: var(--mainBlue);
  border: 0.1rem solid var(--mainWhite);
  padding: 0.1rem 0.5rem 0.3rem 0.4rem;
  border-radius: 50%;
}

.nav-link:hover {
  color: var(--secYellow);
}

.nav-info-items {
  color: var(--mainGrey);
}

.banner-title {
  font-family: "Bree Serif", serif;
  font-size: 3rem;
  color: var(--mainBlack);
}

.title-underline {
  width: 12rem;
  height: 0.3rem;
  margin: 0 auto;
  position: relative;
  border-radius: 2rem;
}

.title-underline__center {
  position: absolute;
  height: 0.6rem;
  background: var(--mainYellow);
  width: 50%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 1rem;
}

/* stairs */

.stairs {
  margin: 0;
}

.img-container {
  position: relative;
  overflow: hidden;
  cursor: pointer;
}

.img-container:hover .stairs-img {
  transform: scale(1.2);
}

.stairs-img {
  transition: all 1s ease-in-out;
}

.img-container:hover .stairs-item-icon {
  transform: translate(0, 0);
}

.product-details {
  height: 30px;
  background-color: var(--mainCherryLight);
  color: var(--mainCherry);
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  display: flex;
  justify-content: space-around;
  padding: 0rem 0.25rem;
  /* font-size: .95rem; */
}

/* Configurator */

.card-img-top {
  height: 20rem;
  /* width: initial;
  object-fit: cover; */
}

.card-body {
  background: rgba(214, 185, 189, 0.1);
  min-height: 114px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

/* footer configurator */

#footer-config {
  /* height: 27rem; */
  font-size: 1.2rem;
  /* margin-bottom: 4px; */
  background: linear-gradient(
      rgba(79, 8, 21, 0.4),
      rgba(79, 8, 21, 0.6),
      rgba(79, 8, 21, 0.6),
      rgb(247, 186, 21, 0.2)
    ),
    url(./img/footer-config/configurator-scara.jpg) center/cover no-repeat fixed;
}

@keyframes moveInRight {
  0% {
    opacity: 0;
    transform: translateX(10rem);
  }

  80% {
    transform: translateX(-1rem);
  }

  100% {
    opacity: 1;
    transform: translate(0);
  }
}

.moveInBottom {
  animation: moveInRight 0.3s ease-out;
  animation-fill-mode: backwards;
  position: fixed;
  top: 2rem;
  left: 40%;
  z-index: 10;
}

.pointer {
  cursor: pointer;
}

.buttonCheckWrapper {
  display: flex !important;
  flex-direction: row !important;
  align-items: center;
}

.footer {
  background-color: rgb(0, 0, 0, 0.9);
  color: var(--mainGrey);
  font-size: 1.1rem;
  position: relative;
  left: 0;
  bottom: 0;
  width: 100%;
  overflow: hidden;
}

.social {
  font-size: 1.7rem;
}

.contact {
  font-size: 1.5rem;
}

.white-link {
  color: var(--mainWhite);
  text-decoration: none;
}

.white-link:hover {
  color: var(--mainGrey);
}

.cherry-link:hover {
  color: var(--mainWhite);
  text-decoration: none;
}

.cherry-link {
  color: var(--mainBlue);
}

.article-link {
  color: var(--mainCherry);
}

.article-link:hover {
  color: var(--secYellow);
}

.contact-background {
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 3%;
}

.contact-wrap {
  font-size: 0.94rem;
}

.footer-copyright {
  background-color: rgb(0, 0, 0);
  font-size: smaller;
}

.wa {
  color: #25d366;
}

.fb {
  color: #4267b2;
}

.despre p {
  /* background: url("http://www.proiectarescari.com/configPhoto/6002eccfcd9514347051660e") no-repeat center center/cover;*/
  font-size: 1.4rem;
  line-height: 2.5rem;
  text-indent: 3rem;
}

.whatsapp-button {
  position: fixed;
  bottom: 2.5rem;
  right: 1rem;
}

.whatsapp-button img {
  width: 4.5rem;
}

@media screen and (max-width: 1019px) {
  .nav-link {
    font-size: 1.3rem;
  }
}

@media screen and (max-width: 978px) {
  .nav-link {
    font-size: 1.2rem;
  }
}

/* @media screen and (max-width: 848px) {
  #footer-config {
    margin-bottom: 100px;
  }
} */

@media screen and (max-width: 767px) {
  #footer-config {
    /* margin-bottom: 118px; */
    height: 29rem;
  }
}

@media screen and (max-width: 480px) {
  h1 {
    font-size: 2rem;
  }

  #footer-config {
    height: 30rem;
    font-size: 1.1rem;
    /* margin-bottom: 111px; */
  }

  .contact {
    font-size: 1.2rem;
  }

  .social {
    font-size: 1.4rem;
  }
}

@media screen and (max-width: 419px) {
  h1 {
    font-size: 1.7rem;
  }

  #footer-config {
    /* margin-bottom: 137px; */
    height: 30rem;
    font-size: 1.1rem;
  }

  .banner-title {
    font-size: 2.3rem;
  }
}

@media screen and (max-width: 321px) {
  h1 {
    font-size: 1.7rem;
  }

  #footer-config {
    height: 33rem;
    font-size: 1.1rem;
  }
}

@media screen and (max-width: 290px) {
  h1 {
    font-size: 1.4rem;
  }

  #footer-config {
    height: 34rem;
    font-size: 0.9rem;
  }
}

#nprogress .bar {
  background: var(--mainCherry) !important;
}

#nprogress .peg {
  box-shadow: 0 0 10px var(--mainCherry), 0 0 5px var(--mainCherry) !important;
}

#nprogress .spinner-icon {
  border-top-color: var(--mainCherry) !important;
  border-left-color: var(--mainCherry) !important;
}
