button {
  -webkit-appearance: none;
  appearance: none;
  -webkit-filter: drop-shadow(0 5px 5px rgba(0, 0, 0, 0.1));
  filter: drop-shadow(0 5px 5px rgba(0, 0, 0, 0.1));
  -webkit-transition: all 0.5s ease;
  filter: drop-shadow(0 5px 5px rgba(0, 0, 0, 0.1));
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
  border: none;
  background: #ffd800;
  border-radius: 30px;
  text-transform: uppercase;
  box-sizing: border-box;
  padding: 15px 40px;
  font-weight: 400;
  font-size: 13px;
  cursor: cursor;
}

button:hover {
  color: #ffffff;
  background: #222222;
  -webkit-filter: drop-shadow(0 0 5px rgba(0, 0, 0, 0.2));
  filter: drop-shadow(0 0 5px rgba(0, 0, 0, 0.2));
}

.wrapper {
  max-width: 100%;
  box-sizing: border-box;
  /* padding: 0 20px; */
  margin: auto;
}

.wrapper a {
  display: inline-block;
  margin: 5px;
}

.wrapper a:first-child {
  margin-left: 0;
}

.slider-wrapper {
  position: relative;
  min-height: calc(100vh - 82px);
  overflow: hidden;
}

.slide {
  min-height: calc(100vh - 82px);
  background-size: cover !important;
}

.slide::before {
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  background: -webkit-gradient( linear, left top, left bottom, from(transparent), to(rgba(0, 0, 0, 0.7)));
  background: linear-gradient(transparent, rgba(0, 0, 0, 0.7));
  bottom: 0;
  left: 0;
}

.previousButton, .nextButton {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
  background: url("http://svgshare.com/i/41_.svg") no-repeat center center / 16px;
  width: 32px;
  height: 32px;
  text-indent: -9999px;
  cursor: pointer;
  display: none !important;
}

.previousButton:hover, .nextButton:hover {
  background: url("http://svgshare.com/i/41_.svg") no-repeat center center / 16px;
}

.previousButton {
  left: 0;
  -webkit-transform: rotate(180deg) translateY(calc(50% + 0px));
  transform: rotate(180deg) translateY(calc(50% + 0px));
}

.previousButton:hover {
  left: -10px;
}

.nextButton {
  right: 0;
}

.nextButton:hover {
  right: -10px;
}

.slider-content {
  text-align: center;
}

.slider-content .inner {
  padding: 0 70px;
  box-sizing: border-box;
  position: absolute;
  width: 100%;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.slider-content .inner button {
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
}

.slider-content .inner h2 {
  font-weight: 900;
  margin: 0 auto;
  max-width: 840px;
  color: #ffffff;
  font-size: 50px;
  line-height: 1;
}

.slider-content .inner h3 {
  color: #ffffff;
  font-size: 30px;
  line-height: 1.5;
  margin: 20px auto 30px;
  max-width: 640px;
}

.slider-content section {
  position: absolute;
  bottom: 20px;
  left: 20px;
}

.slider-content section span {
  color: #ffffff;
}

.slider-content section span {
  color: rgba(255, 255, 255, 0.5);
  font-size: 12px;
  display: inline-block;
  text-align: left;
  line-height: 1.4;
  vertical-align: middle;
  margin-left: 10px;
}

.slider-content section img {
  width: 40px;
  height: 40px;
  border: solid 2px rgba(255, 255, 255, 0.5);
  border-radius: 100%;
  vertical-align: middle;
}

.slider-content section span strong {
  color: #ffffff;
  font-size: 14px;
  display: block;
}

/* .img-fluid {
  max-width: 100%;
  height: auto;
} */

@media (max-height: 500px) {
  .slider-wrapper, .slide {
    height: calc(100vh - 75px);
  }
}

@media (max-width: 640px) {
  .slider-wrapper, .slide {
    height: calc(80vh - 75px);
  }
}

@media (max-height: 600px) {
  .slider-content .inner h2 {
    font-size: 32px;
  }
}

@media (max-width: 640px) {
  .slider-content .inner h2 {
    font-size: 32px;
  }
}