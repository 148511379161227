@import url(https://fonts.googleapis.com/css?family=Roboto:400,400i,500,500i,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Bree+Serif&display=swap);
:root {
  --mainCherry: #4f0815;
  --mainCherryLight: #cccaca;
  --mainYellow: rgba(249, 228, 148, 9);
  --secYellow: #f7ba15;
  --thirdYellow: rgba(245, 197, 126, 0.2);
  --mainBlue: rgba(14, 168, 245, 0.7);
  --mainWhite: #fff;
  --mainBlack: #000;
  --yellowTrans: rgba(249, 228, 148, 0.5);
  --mainGrey: #eeeeee;
}

html {
  min-height: 100%;
  position: relative;
}

body {
  font-family: "Bree Serif", serif;
  background-color: var(--mainGrey);
  color: var(--mainBlack);
  margin: 0;
  padding: 0;
  overflow-x: hidden !important;
}

a {
  color: var(--mainBlack);
}

a:hover {
  text-decoration: none;
  color: var(--mainBlack);
}

h3 {
  font-size: 1.6rem;
}

h5 {
  font-size: 1.1rem;
}

/* global style */

.btn-blue {
  color: var(--mainWhite);
  background-color: var(--mainBlue);
}

.btn-blue:hover {
  color: var(--mainCherry);
  background-color: var(--mainWhite);
  border-color: var(--mainBlack);
}

.btn-cherry-yellow {
  color: var(--mainWhite);
  background-color: var(--mainCherry);
}

.btn-cherry-yellow:hover {
  color: var(--mainCherry);
  background-color: var(--secYellow);
}

/* nav links */

.navbar {
  background-color: var(--mainCherry);
  font-family: "Bree Serif", serif;
  padding: 0px;
}

.navbar-toggler {
  outline: none !important;
}

.navbar-collapse {
  transition: all 0.5s ease-in-out;
}

.nav-link {
  color: var(--mainGrey);
  transition: all 0.5s ease-in-out;
  font-size: 1.4rem;
}

.logo img {
  height: auto;
  width: 4.5rem;
}

.toggler-icon {
  font-size: 2.5rem;
  color: var(--mainWhite);
}

.phone-icon {
  color: var(--mainBlue);
  border: 0.1rem solid var(--mainWhite);
  padding: 0.1rem 0.5rem 0.3rem 0.4rem;
  border-radius: 50%;
}

.nav-link:hover {
  color: var(--secYellow);
}

.nav-info-items {
  color: var(--mainGrey);
}

.banner-title {
  font-family: "Bree Serif", serif;
  font-size: 3rem;
  color: var(--mainBlack);
}

.title-underline {
  width: 12rem;
  height: 0.3rem;
  margin: 0 auto;
  position: relative;
  border-radius: 2rem;
}

.title-underline__center {
  position: absolute;
  height: 0.6rem;
  background: var(--mainYellow);
  width: 50%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 1rem;
}

/* stairs */

.stairs {
  margin: 0;
}

.img-container {
  position: relative;
  overflow: hidden;
  cursor: pointer;
}

.img-container:hover .stairs-img {
  transform: scale(1.2);
}

.stairs-img {
  transition: all 1s ease-in-out;
}

.img-container:hover .stairs-item-icon {
  transform: translate(0, 0);
}

.product-details {
  height: 30px;
  background-color: var(--mainCherryLight);
  color: var(--mainCherry);
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  display: flex;
  justify-content: space-around;
  padding: 0rem 0.25rem;
  /* font-size: .95rem; */
}

/* Configurator */

.card-img-top {
  height: 20rem;
  /* width: initial;
  object-fit: cover; */
}

.card-body {
  background: rgba(214, 185, 189, 0.1);
  min-height: 114px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

/* footer configurator */

#footer-config {
  /* height: 27rem; */
  font-size: 1.2rem;
  /* margin-bottom: 4px; */
  background: linear-gradient(
      rgba(79, 8, 21, 0.4),
      rgba(79, 8, 21, 0.6),
      rgba(79, 8, 21, 0.6),
      rgb(247, 186, 21, 0.2)
    ),
    url(/static/media/configurator-scara.fb754ec8.jpg) center/cover no-repeat fixed;
}

@keyframes moveInRight {
  0% {
    opacity: 0;
    transform: translateX(10rem);
  }

  80% {
    transform: translateX(-1rem);
  }

  100% {
    opacity: 1;
    transform: translate(0);
  }
}

.moveInBottom {
  animation: moveInRight 0.3s ease-out;
  animation-fill-mode: backwards;
  position: fixed;
  top: 2rem;
  left: 40%;
  z-index: 10;
}

.pointer {
  cursor: pointer;
}

.buttonCheckWrapper {
  display: flex !important;
  flex-direction: row !important;
  align-items: center;
}

.footer {
  background-color: rgb(0, 0, 0, 0.9);
  color: var(--mainGrey);
  font-size: 1.1rem;
  position: relative;
  left: 0;
  bottom: 0;
  width: 100%;
  overflow: hidden;
}

.social {
  font-size: 1.7rem;
}

.contact {
  font-size: 1.5rem;
}

.white-link {
  color: var(--mainWhite);
  text-decoration: none;
}

.white-link:hover {
  color: var(--mainGrey);
}

.cherry-link:hover {
  color: var(--mainWhite);
  text-decoration: none;
}

.cherry-link {
  color: var(--mainBlue);
}

.article-link {
  color: var(--mainCherry);
}

.article-link:hover {
  color: var(--secYellow);
}

.contact-background {
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 3%;
}

.contact-wrap {
  font-size: 0.94rem;
}

.footer-copyright {
  background-color: rgb(0, 0, 0);
  font-size: smaller;
}

.wa {
  color: #25d366;
}

.fb {
  color: #4267b2;
}

.despre p {
  /* background: url("http://www.proiectarescari.com/configPhoto/6002eccfcd9514347051660e") no-repeat center center/cover;*/
  font-size: 1.4rem;
  line-height: 2.5rem;
  text-indent: 3rem;
}

.whatsapp-button {
  position: fixed;
  bottom: 2.5rem;
  right: 1rem;
}

.whatsapp-button img {
  width: 4.5rem;
}

@media screen and (max-width: 1019px) {
  .nav-link {
    font-size: 1.3rem;
  }
}

@media screen and (max-width: 978px) {
  .nav-link {
    font-size: 1.2rem;
  }
}

/* @media screen and (max-width: 848px) {
  #footer-config {
    margin-bottom: 100px;
  }
} */

@media screen and (max-width: 767px) {
  #footer-config {
    /* margin-bottom: 118px; */
    height: 29rem;
  }
}

@media screen and (max-width: 480px) {
  h1 {
    font-size: 2rem;
  }

  #footer-config {
    height: 30rem;
    font-size: 1.1rem;
    /* margin-bottom: 111px; */
  }

  .contact {
    font-size: 1.2rem;
  }

  .social {
    font-size: 1.4rem;
  }
}

@media screen and (max-width: 419px) {
  h1 {
    font-size: 1.7rem;
  }

  #footer-config {
    /* margin-bottom: 137px; */
    height: 30rem;
    font-size: 1.1rem;
  }

  .banner-title {
    font-size: 2.3rem;
  }
}

@media screen and (max-width: 321px) {
  h1 {
    font-size: 1.7rem;
  }

  #footer-config {
    height: 33rem;
    font-size: 1.1rem;
  }
}

@media screen and (max-width: 290px) {
  h1 {
    font-size: 1.4rem;
  }

  #footer-config {
    height: 34rem;
    font-size: 0.9rem;
  }
}

#nprogress .bar {
  background: var(--mainCherry) !important;
}

#nprogress .peg {
  box-shadow: 0 0 10px var(--mainCherry), 0 0 5px var(--mainCherry) !important;
}

#nprogress .spinner-icon {
  border-top-color: var(--mainCherry) !important;
  border-left-color: var(--mainCherry) !important;
}

.slide h2, .slide h3 {
  transition: all 0.3s ease;
  transform: translateY(-20px);
  opacity: 0;
}

.slide button {
  transition: all 0.3s ease;
  transform: translateY(20px);
  opacity: 0;
}

.slide p {
  transition: all 0.3s ease;
  transform: translateY(20px);
  opacity: 0;
}

.slide section * {
  transition: all 0.3s ease;
}

.slide section img {
  transform: translateX(-10px);
  opacity: 0;
}

.slide section span {
  transform: translateY(-10px);
  opacity: 0;
}

.slide section span strong {
  transform: translateY(10px);
  opacity: 0;
}

.slide.animateIn.previous h2, .slide.current h2, .slide.animateIn.next h2, .slide.animateIn.previous h3, .slide.current h3, .slide.animateIn.next h3, .slide.animateIn.previous button, .slide.current button, .slide.animateIn.next button, .slide.animateIn.previous p, .slide.current p, .slide.animateIn.next p, .slide.animateIn.previous section *, .slide.current section *, .slide.animateIn.next section * {
  transform: translateX(0);
  transition-delay: 0.9s;
  opacity: 1;
}

.slide.animateIn.previous p, .slide.animateIn.next p {
  transition-delay: 1.1s;
}

.slide.animateIn.previous button, .slide.animateIn.next button {
  transition-delay: 1.3s;
}

.slide.animateIn.previous section img, .slide.animateIn.next section img {
  transition-delay: 1.3s;
}

.slide.animateIn.previous section span, .slide.animateIn.next section span {
  transition-delay: 1.4s;
}

.slide.animateIn.previous section span strong, .slide.animateIn.next section span strong {
  transition-delay: 1.5s;
}

.slide.animateOut h2, .slide.animateOut h3 {
  transition-delay: 0.3s;
}

.slide.animateOut p {
  transition-delay: 0.2s;
}

.slide.animateOut section span {
  transition-delay: 0.1s;
}

.slide.animateOut section span strong {
  transition-delay: 0s;
}
button {
  -webkit-appearance: none;
  appearance: none;
  filter: drop-shadow(0 5px 5px rgba(0, 0, 0, 0.1));
  -webkit-transition: all 0.5s ease;
  filter: drop-shadow(0 5px 5px rgba(0, 0, 0, 0.1));
  transition: all 0.5s ease;
  border: none;
  background: #ffd800;
  border-radius: 30px;
  text-transform: uppercase;
  box-sizing: border-box;
  padding: 15px 40px;
  font-weight: 400;
  font-size: 13px;
  cursor: cursor;
}

button:hover {
  color: #ffffff;
  background: #222222;
  filter: drop-shadow(0 0 5px rgba(0, 0, 0, 0.2));
}

.wrapper {
  max-width: 100%;
  box-sizing: border-box;
  /* padding: 0 20px; */
  margin: auto;
}

.wrapper a {
  display: inline-block;
  margin: 5px;
}

.wrapper a:first-child {
  margin-left: 0;
}

.slider-wrapper {
  position: relative;
  min-height: calc(100vh - 82px);
  overflow: hidden;
}

.slide {
  min-height: calc(100vh - 82px);
  background-size: cover !important;
}

.slide::before {
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  background: linear-gradient(transparent, rgba(0, 0, 0, 0.7));
  bottom: 0;
  left: 0;
}

.previousButton, .nextButton {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
  background: url("http://svgshare.com/i/41_.svg") no-repeat center center / 16px;
  width: 32px;
  height: 32px;
  text-indent: -9999px;
  cursor: pointer;
  display: none !important;
}

.previousButton:hover, .nextButton:hover {
  background: url("http://svgshare.com/i/41_.svg") no-repeat center center / 16px;
}

.previousButton {
  left: 0;
  transform: rotate(180deg) translateY(calc(50% + 0px));
}

.previousButton:hover {
  left: -10px;
}

.nextButton {
  right: 0;
}

.nextButton:hover {
  right: -10px;
}

.slider-content {
  text-align: center;
}

.slider-content .inner {
  padding: 0 70px;
  box-sizing: border-box;
  position: absolute;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.slider-content .inner button {
  transition-delay: 0s;
}

.slider-content .inner h2 {
  font-weight: 900;
  margin: 0 auto;
  max-width: 840px;
  color: #ffffff;
  font-size: 50px;
  line-height: 1;
}

.slider-content .inner h3 {
  color: #ffffff;
  font-size: 30px;
  line-height: 1.5;
  margin: 20px auto 30px;
  max-width: 640px;
}

.slider-content section {
  position: absolute;
  bottom: 20px;
  left: 20px;
}

.slider-content section span {
  color: #ffffff;
}

.slider-content section span {
  color: rgba(255, 255, 255, 0.5);
  font-size: 12px;
  display: inline-block;
  text-align: left;
  line-height: 1.4;
  vertical-align: middle;
  margin-left: 10px;
}

.slider-content section img {
  width: 40px;
  height: 40px;
  border: solid 2px rgba(255, 255, 255, 0.5);
  border-radius: 100%;
  vertical-align: middle;
}

.slider-content section span strong {
  color: #ffffff;
  font-size: 14px;
  display: block;
}

/* .img-fluid {
  max-width: 100%;
  height: auto;
} */

@media (max-height: 500px) {
  .slider-wrapper, .slide {
    height: calc(100vh - 75px);
  }
}

@media (max-width: 640px) {
  .slider-wrapper, .slide {
    height: calc(80vh - 75px);
  }
}

@media (max-height: 600px) {
  .slider-content .inner h2 {
    font-size: 32px;
  }
}

@media (max-width: 640px) {
  .slider-content .inner h2 {
    font-size: 32px;
  }
}
a:visited, a:link {
  outline: none !important;
  border: none !important;
}
